import React from "react"
import Layout from "../components/layout"

export default function Partners() {
  return (
    <Layout>
      <section className="page">
        <h2 className="page__heading">Partner With Squirrel</h2>
        <h3 className="page__subheading">Have Squirrel Manage You RV Rental On The Squirrel Platform And Enjoy Profit Sharing</h3>
        <p className="page__intro">
          Here are the requirements to partner with Squirrel:
        </p>
        <ul className="page__list">
          <li className="page__list__body">Model Year Must Be 2020 or After.</li>
          <li className="page__list__body">Eligible Models:
            <ul>
              <li className="page__list__body">Wingamm Oasi 540.1</li>
              <li className="page__list__body">Winnebago Solis</li>
              <li className="page__list__body">Winnebago Solis Pocket</li>
              <li className="page__list__body">Winnebago Solis NPF</li>
              <li className="page__list__body">Winnebago Travato</li>
              <li className="page__list__body">Airstream Interstate 19</li>
              <li className="page__list__body">Airstream Interstate 19X</li>
              <li className="page__list__body">Airstream Interstate 19SE</li>
              <li className="page__list__body">Thor Rize 18m</li>
            </ul>
          </li>
          <li className="page__list__body">Eligible Colors:
            <ul>
              <li className="page__list__body">Black</li>
              <li className="page__list__body">White</li>
              <li className="page__list__body">Silver / Grey</li>
            </ul>
          </li>
        </ul>
        <p className="page__body">If you own a motorhome that fits Squirrel’s criteria and would like to join or interest list please <a className="page__list__body--no-symbol" href="mailto:info@nicesquirrel.com">contact us to learn more about how to partner with Squirrel!</a>
        </p>
      </section>
    </Layout>
  )
}
